import React, { useState, useEffect } from "react";
import style from './index.module.less'
import { Layout, SEO } from '@/components/index'
import { useIntl } from "react-intl";
import LinkSVG from '@/images/SVG/manual_link_icon.svg'
import useBigScreen from "@/hooks/bigScreen"
import useNavigate from '@/hooks/useNavigate'
import getCountry from '@/utils/getCountry.js'

const Index = () => {
    const intl = useIntl()
    const ifPc = useBigScreen(768)
    const region = getCountry()

    const [activeItem, setActiveItem] = useState(0)
    const ifNoShowOne = region == 'th'

    const list = [
        {
            type: "XREAL One",
            hash: "one",
            list: [
                {
                    name: "XREAL_One_User_Manuals",
                    link: "/support/XREAL_One_User_Manuals"
                }
            ]
        },
        {
            type: "XREAL air 2 Ultra",
            hash: "air_2_ultra",
            list: [
                {
                    name: "Air_2_Ultra_User_Manuals",
                    link: "/support/Air_2_Ultra_User_Manuals"
                }, {
                    name: "Glasses_Button_Functions",
                    link: "/support/Glasses_Button_Functions"
                }
            ]
        },
        {
          type: "XREAL Beam Pro",
          hash: "beam_pro",
          list: [
              {
                  name: "XREAL Beam_Pro_User_Manuals",
                  link: "/support/XREAL_Beam_Pro_User_Manuals"
              }
          ]
        }, 
        {
            type: "XREAL air 2 Pro",
            hash: "air_2_pro",
            list: [
                {
                    name: "Air_2_Pro_User_Manuals",
                    link: "/support/XREAL_Air2Pro_Quick_Start_Guide_pdf"
                }
            ]
        },
        {
            type: "XREAL air 2",
            hash: "air_2",
            list: [
                {
                    name: "Air_2_User_Manuals",
                    link: "/support/XREAL_Air2_Quick_Start_Guide_pdf"
                }
            ]
        },
        {
            type: "XREAL air",
            hash: "air",
            list: [
                {
                    name: "Air_User_Manuals",
                    link: "/support/XREAL_AIR_MANUAL_EN&JP&KR&CN_pdf"
                }
            ]
        },
        {
            type: "XREAL Light",
            hash: "light",
            list: [
                {
                    name: "Light_User_Manuals",
                    link: "/support/Nreal_Light_After_Sales_&_Warranty_US_pdf"
                }
            ]
        },
        {
            type: "XREAL Beam",
            hash: "beam",
            list: [
                {
                    name: "Beam_User_Manuals",
                    link: "/support/XREAL_Beam_MANUAL_CN&EN&JP&KR_pdf"
                }
            ]
        },
        {
            type: "XREAL Adapter",
            hash: "adapter",
            list: [
                {
                    name: "Adapter_User_Manuals",
                    link: "/support/XREAL_ADA_MANUAL_pdf"
                }
            ]
        }
    ]
    const list_no_one = list.slice(1); // 去掉 "One"

    const list_show = ifNoShowOne ? list_no_one : list

    // 解析 URL Hash 并设置 activeItem
    useEffect(() => {
      if (typeof window !== "undefined") {
          const hash = window.location.hash.replace("#", "");
          const index = list_show.findIndex(item => item.hash === hash);
          if (index !== -1) {
              setActiveItem(index);
          }
      }
    }, [list_show]);


    // 监听 hash 变化（用户手动修改 URL 时触发）
    useEffect(() => {
      const handleHashChange = () => {
          const hash = window.location.hash.replace("#", "").toLowerCase().replace(/_/g, " ");
          const index = list_show.findIndex(item => item.type.toLowerCase() === hash);
          if (index !== -1) {
              setActiveItem(index);
          }
      };

      window.addEventListener("hashchange", handleHashChange);
      return () => window.removeEventListener("hashchange", handleHashChange);
    }, [list_show]);

    // 切换 Tab 并更新 URL Hash
    const handleTabClick = (index, hash) => {
        setActiveItem(index);
        window.location.hash = hash;
    };

    return (
        <Layout menuBlack={true}>
            <SEO page="User Manual" />
            <div className={style.box}>
                <div className={style.title}>
                    {intl.formatMessage({ id: "support_manuals" })}
                </div>
                {ifPc ? <div className={style.guide}>
                    <div className={style.guideLeft}>
                        {list_show.map((item, index) => (
                            <div className={style.guideLeftItem} key={`support_guide_type${index}`} style={{ borderBottom: activeItem == index ? "1px solid #000" : "1px solid transparent" }}
                                onClick={() => handleTabClick(index, item.hash)}
                            >
                                <span className={style.guideTypeTitle}
                                    style={{ color: activeItem == index ? "#000" : "rgba(0,0,0,0.2)" }}

                                >
                                    {item.type}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className={style.guideRight}>
                        {list_show[activeItem].list.map((item, index) => (
                            <div className={style.guideRightItem} key={`support_guide_type${activeItem}_manual${index}`}
                                onClick={() => {
                                    if (!!item.linkOut) {
                                        window.open(item.link)
                                    } else {
                                        useNavigate(item.link, 'out')
                                    }

                                }}
                            >
                                <div className={style.guideManualName}>{item.name}</div>
                                <LinkSVG />
                            </div>
                        ))}
                    </div>
                </div> :
                    <div className={style.guideM}>
                        {list.map((item, index) => (
                            <div className={style.guideMItem} key={`support_guide_mobile_type${index}`}>
                                <div className={style.guideTypeTitle}>{item.type}</div>
                                <div className={style.guideMItemList}>
                                    {item.list.map((value, key) => (
                                        <div className={style.guideMItemListItem} key={`support_guide_mobile_type${index}_manual${key}`}
                                            onClick={() => window.open(value.link)}
                                        >
                                            <div className={style.guideManualName}>{value.name}</div>
                                            <LinkSVG />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>}


            </div>
        </Layout>
    )
}

export default Index